import _ from 'lodash';
import { CUSTOM_PATH, CONSTANTS } from 'generic/core/constants';
import countries from 'generic/locales/countries-fr.json';
import languagesFull from 'generic/locales/language-codes-full_json.json';

const languages = languagesFull.reduce((acc, lang) => {
  if (lang.alpha2) {
    acc[lang.alpha2] = lang.French;
  }
  return acc;
}, {});

let fr = {
  fr: {
    translation: {
      commons: {
        months: {
          january: 'Janvier',
          february: 'Février',
          march: 'Mars',
          april: 'Avril',
          may: 'Mai',
          june: 'Juin',
          july: 'Juillet',
          august: 'Août',
          september: 'Septembre',
          october: 'Octobre',
          november: 'Novembre',
          december: 'Décembre',
        },
        short_months: {
          jan: 'Jan.',
          feb: 'Fev.',
          mar: 'Mar.',
          apr: 'Avr.',
          may: 'Mai',
          jun: 'Juin',
          jul: 'Juil.',
          aug: 'Aou.',
          sep: 'Sep.',
          oct: 'Oct.',
          nov: 'Nov.',
          dec: 'Déc.',
        },
        weekdays: {
          sunday: 'Dimanche',
          monday: 'Lundi',
          tuesday: 'Mardi',
          wednesday: 'Mercredi',
          thursday: 'Jeudi',
          friday: 'Vendredi',
          saturday: 'Samedi',
        },
        periods: {
          today: 'Aujourd\'hui',
          yesterday: 'Hier',
          this_week: 'Cette semaine',
          last_week: 'La semaine dernière',
          last_7_days: 'Les 7 derniers jours',
          this_month: 'Ce mois-ci',
          last_month: 'Le mois dernier',
          last_30_days: 'Les 30 derniers jours',
          this_year: 'Cette année',
          last_year: 'L\'année dernière',
          last_12_months: 'Les 12 derniers mois',
        },
      },
      logon: {
        welcome: 'Bienvenue',
        log_in: 'Connexion',
        to_login: 'Connexion',
        login: 'Adresse mail',
        login_placeholder: 'example@domain.com',
        password: 'Mot de passe',
        password_placeholder: 'Entrer un mot de passe...',
        password_aria_show: 'montrer le mot de passe',
        password_aria_hide: 'masquer le mot de passe',
        password_confirmation: 'Confirmation du mot de passe',
        fail_auth: 'Votre identifiant ou votre mot de passe est incorrect, merci de réessayer',
        forgotten_password: 'Mot de passe oublié ?',
        forgotten_password_back: 'J\'ai retrouvé mon mot de passe',
        forgotten_password_title: 'Réinitialisation du mot de passe',
        forgotten_password_label:
          'Entrez votre adresse e-mail pour recevoir un lien de réinitialisation du mot de passe',
        forgotten_password_success_title: 'E-mail envoyé !',
        forgotten_password_success_desc: `
          Un e-mail de réinitialisation a été envoyé.
          Vérifiez votre boite de réception pour continuer.`,
        forgotten_password_success_button: 'Retour à la connexion',
        forgotten_password_error: 'Erreur lors de la demande',
        reset_password_confirmation_title: 'Modifier votre mot de passe',
        reset_password_confirmation_success: 'Mot de passe mis à jour',
        reset_password_confirmation_field_label: 'Nouveau mot de passe',
        reset_password_confirmation_submit: 'Réinitialiser le mot de passe',
      },
      header: {
        web_tracking: 'Veille Web',
        advanced_search: 'Recherche avancée',
        number_criteria_one: ' ({{count}} critère)',
        number_criteria_other: ' ({{count}} critères)',
        qes: 'QES',
        carts: 'Paniers',
        collections: 'Collections',
        newsletters: 'Newsletters',
        alerts: 'Alertes',
        mailing_lists: 'Listes de diffusion',
        explore: 'Exploration',
        dashboard: 'Dashboard',
        results: 'Résultats',
        relations: 'Relations',
        switch_to_question: 'Passer en recherche par question',
        switch_to_search: 'Retourner en recherche classique',
        users_mailing_list: 'Utilisateurs des listes de diffusion',
        schedules: 'Tâches planifiées',
        imports_monitoring: 'Suivi des imports',
      },
      route: {
        results: `${CONSTANTS.PROJECT_NAME} - Résultats`,
        article: `${CONSTANTS.PROJECT_NAME} - Article`,
        collections: `${CONSTANTS.PROJECT_NAME} - Collections`,
        imports_monitoring: `${CONSTANTS.PROJECT_NAME} - Suivi des imports`,
        dashboard: `${CONSTANTS.PROJECT_NAME} - Dashboard`,
        anr: `${CONSTANTS.PROJECT_NAME} - Veille Web`,
        carts: `${CONSTANTS.PROJECT_NAME} - Paniers`,
        newsletters: `${CONSTANTS.PROJECT_NAME} - Newsletters`,
        alerts: `${CONSTANTS.PROJECT_NAME} - Alertes`,
        mailing_lists: `${CONSTANTS.PROJECT_NAME} - Listes de diffusion`,
        users: `${CONSTANTS.PROJECT_NAME} - Utilisateurs`,
        home: `${CONSTANTS.PROJECT_NAME} - Accueil`,
        profile: `${CONSTANTS.PROJECT_NAME} - Profil`,
        app_name: `${CONSTANTS.PROJECT_NAME}`,
        relations: `${CONSTANTS.PROJECT_NAME} - Dashboard Relations`,
        users_mailing_list: `${CONSTANTS.PROJECT_NAME} - Utilisateurs des listes de diffusion`,
        schedules: `${CONSTANTS.PROJECT_NAME} - Tâches planifiées`,
        schedule_show: `${CONSTANTS.PROJECT_NAME} - Tâche planifiée`,
      },
      form: {
        loading_results: 'Calcul des résultats en cours',
        ask: 'Posez votre question',
        edit: 'Modifier',
        edit_fast: 'Modification rapide',
        search: 'Rechercher',
        delete: 'Supprimer',
        save: 'Enregistrer',
        clear: 'Vider',
        cancel: 'Annuler',
        reset: 'Réinitialiser',
        title: 'Titre',
        test: 'Tester',
        send: 'Envoyer',
        unspecified: 'Non précisé',
        omni_placeholder: 'Rechercher dans la {{activeBaseLabel}}',
        default_base_label: 'Base de connaissance',
        add_new_field: 'Ajouter un autre champ',
        errors: {
          error_from_test: 'Erreur lors du test des paramètres',
          mandatory: 'Champ obligatoire',
          must_be_integer: 'La valeur doit être un nombre entier',
          must_be_more_than: 'La valeur doit être supérieure à',
          must_be_less_than: 'La valeur doit être inférieure à',
          must_select_at_least_one_lang: 'Sélectionnez au moins une langue',
          must_contain_email: 'Doit contenir au moins 1 email',
          invalid_date: 'Format de date invalide',
          invalid_hour: 'Format d\'heure invalide',
          no_from_after_to: 'La date de début doit être antérieure à la date de fin',
          no_past_dates: 'Les dates dans le passé ne sont pas autorisées',
          no_future_dates: 'Les dates dans le futur ne sont pas autorisées',
          min_date_message: 'Merci de saisir une date supérieure à',
          max_date_message: 'Merci de saisir une date inférieure à',
          not_valid_email: 'Merci de saisir un mail valide',
        },
        datepicker: {
          from: '(du)',
          to: '(au)',
          placeholder: 'JJ/MM/AAAA',
        },
        checkbox: {
          and: 'Et',
          all: 'Tout sélectionner / désélectionner',
        },
        create_new_value: 'Créer une nouvelle valeur',
      },
      file_upload: {
        drag_and_drop: 'Ajoutez des documents ou glisser déposer',
        error_uploading_file: 'Erreur au téléchargement du fichier',
        file_type_not_allowed: 'Ce type de fichier n\'est pas autorisé',
        upload: 'Télécharger',
        upload_documents: 'Télécharger des documents',
        uploading_file_success: 'Le document "{{file}}" a bien été téléchargé',
      },
      config: {
        active_base: 'Base active',
        error_fetching_config: 'Erreur lors de la récupération de la configuration du formulaire',
        error_loading_base: 'Erreur lors du chargement de la base',
      },
      annotations: {
        Coldconcept: 'Concept',
        Concept: 'Concept',
        Person: 'Personne',
        Date: 'Date',
        Location: 'Lieu',
        Company: 'Société',
        Product: 'Produit',
        Organization: 'Organisation',
        Organonoff: 'Organisation non officielle',
        Event: 'Événement',
        Media: 'Média',
        Money: 'Argent',
        Object: 'Objet',
        Uppers: 'Acronyme',
      },
      dashboard: {
        languages,
        countries,
        error_fetching_dashboards: 'Erreur lors de la récupération des dashboards',
        error_fetching_dashboard_widget: 'Récupération des datas de "{{widgetTitle}}" impossible',
        feeling_values: {
          '-4': 'extrêmement négatif',
          '-3': 'très négatif',
          '-2': 'négatif',
          '-1': 'mal vu',
          0: 'neutre',
          1: 'bien vu',
          2: 'positif',
          3: 'très positif',
          4: 'extrêmement positif',
        },
        root: 'Racine',
        widget: {
          type: {
            bar: 'Barres horizontales',
            cooccurrences: 'Cooccurrences',
            datatable: 'Tableau',
            documentlist: 'Liste de documents',
            map: 'Carte',
            pie: 'Camembert',
            relations: 'Relations',
            sankey: 'Liens fréquents',
            solidgauge: 'Gauge circulaire',
            spline: 'Historique',
            sunburst: 'Camembert par niveaux',
            treemap: 'Treemap',
            wordcloud: 'Nuage de mots',
          },
          form: {
            additionalQuery: 'additionalQuery - Requête additionnelle',
            aggregates:
              'aggregates - Agrégats (séparés par des virgules + "agg_" avant les noms) - première facette par défaut',
            facetmax2: 'facetmax2 - Nombre max 2',
            facetmax: 'facetmax - Nombre max',
            facets: 'facets - Champs (séparés par des virgules)',
            humanizeDocState: 'humanizeDocState - Convertir les IDs d\'états en texte',
            humanizeLangs: 'humanizeLangs - Convertir le code langue en nom complet',
            withExploration: 'withExploration - Avec l\'exploration de nouveaux liens et nœuds',
            totalunique: 'totalunique - Afficher le total dans le titre',
            computeLink: 'computeLink - Méthode de génération de lien',
            noComputeLinkMethod: 'Pas de méthode',
            computeLinkWithGoToMitre: 'Go To Mitre',
            computeLinkWithGoToAlienVault: 'Go To Alien Vault',
            list: 'list - Liste de correspondance (ID liste des dossiers d\'Ask\'n\'Read)',
            monoColors: 'monoColors - Couleurs unique dégradée',
            pivots: 'pivots - Pivots (séparés par des virgules + "agg_" avant les noms) - seconde facette par défaut',
            slice: 'slice - Nombre de documents',
            sort: 'sort - Tri des documents',
            title: 'Titre du widget',
            tones: 'tones - Les données représentent des scores de tonalités',
            type: 'Type de widget',
          },
          attack_patterns: 'Modèles d\'attaque',
          business_concepts: 'Concepts Business',
          collect_date: 'Date de collecte',
          compagnies_mentioned: 'Sociétés mentionnées',
          concepts: 'Concepts',
          concepts_by_country: 'Concepts par pays',
          concepts_cited_by_country: 'Concepts cités par pays',
          cooccurrences_graph: 'Graphe de cooccurrences',
          countries_mentioned: 'Les pays cités',
          culture_concepts: 'Concepts Culture',
          documents_published_by_dates: 'Documents publiés par dates',
          documents_published_collected_by_dates: 'Documents publiés et collectés par dates',
          domaine: 'Domaine',
          education_concepts: 'Concepts Éducation',
          energy_concepts: 'Concepts Énergie',
          environmental_concepts: 'Concepts Environnement',
          events: 'Événements',
          health_concepts: 'Concepts Santé',
          innovation_concepts: 'Concepts Innovation',
          justice_concepts: 'Concepts Justice',
          languages: 'Langues',
          latest_published_documents: 'Derniers documents publiés',
          malwares: 'Logiciels malveillants',
          count: 'Décompte',
          number_articles: 'Nombre d\'articles',
          number_articles_compagnies: 'Nombre d\'articles pour cette société',
          number_articles_persons: 'Nombre d\'articles pour cette personne',
          observed_data: 'Données observées',
          organizations: 'Organisations',
          organizations_mentioned: 'Organisations mentionnées',
          persons_and_concepts: 'Personnes et concepts',
          persons_events: 'Personnes et évènements',
          persons_mentioned: 'Personnes mentionnées',
          places_mentioned: 'Lieux mentionnés',
          places_mentioned_in_documents: 'Lieux mentionnés dans les documents',
          political_concepts: 'Concepts Politique',
          publication_date: 'Date de publication',
          relations_graph: 'Graphe de relations',
          security_concepts: 'Concepts Sécurité',
          society_concepts: 'Concepts Société',
          sources_by_original_folders: 'Sources par dossiers d\'origine',
          sources_mentioned: 'Sources mentionnées',
          sport_concepts: 'Concepts Sport',
          status_documents: 'États des documents',
          technology_concepts: 'Concepts Technologie',
          threat_actors: 'Acteurs Menaçants',
          tonalities: 'Tonalités',
          tools: 'Outils',
          top_50_domain: 'Top 50 des sources',
          top_50_left_part: 'Top 50 partie gauche',
          top_50_right_part: 'Top 50 partie droite',
          top_50_central_part: 'Top 50 partie centrale',
          unofficial_organizations: 'Organisations non officielles',
          values: 'Valeurs',
          part_left_type: 'Type de la partie gauche',
          relation_type: 'Type de relation',
          part_right_type: 'Type de la partie droite',
          no_new_links_found: 'Aucun nouveau lien trouvé',
          at_least_one_link_or_node: 'Veuilllez sélectionner au moins un type de lien et un type de nœud',
          at_least_one_node: 'Veuilllez sélectionner au moins un type de nœud',
          exploration: 'Étapes d\'exploration',
          shift_click_to_explore: 'Maj + clic sur un nœud pour explorer tous ses liens',
          cant_refine_on_field: 'Cet élément ne peut pas être filtré',
        },
        visibility: 'Visibilité du dashboard',
        cmd_click_for_quick_results: 'Clic simple pour tout filtrer / ⌘ + click pour voir les résultats instantanément',
        ctrl_click_for_quick_results:
          'Clic simple pour tout filtrer / Ctrl + clic pour voir les résultats instantanément',
        quick_results_title: 'Résultats contextuels',
        context_button_title: 'Export',
        download_csv: 'Télécharger au format CSV',
        download_all_csv: 'Télécharger toutes les données au format CSV',
        download_jpeg: 'Télécharger au format JPEG',
        download_pdf: 'Télécharger au format PDF',
        download_png: 'Télécharger au format PNG',
        download_svg: 'Télécharger au format SVG',
        download_xls: 'Télécharger au format XLS',
        exit_full_screen: 'Quitter le mode plein écran',
        print_chart: 'Imprimer le graphe',
        view_full_screen: 'Afficher en plein écran',
        view_data: 'Afficher les données',
        links_types: 'Types des liens',
        nodes_types: 'Types des nœuds',
        filter_chart_items: 'Filtrer les éléments',
        reset_zoom: 'Annuler le zoom',
        tooltip_content: {
          source: 'Source :',
          destination: 'Destination :',
          type: 'Type :',
          weight: 'Poids :',
          bidirectional: 'Bidirectionnel :',
          yes: 'Oui',
        },
        edit_widget_menu: 'Modifier les paramètres',
        edit_widget: 'Modifier les paramètres du widget',
        edit_dashboard: 'Modifier les paramètres du tableau de bord courant',
        add_new_dashboard: 'Créer un nouveau tableau de bord',
        add_new_widget: 'Ajouter un graphique',
        dashboard_title: 'Titre du tableau de bord',
        delete_widget: 'Supprimer le graphique',
        delete_widget_sure: 'Êtes-vous sûr de vouloir supprimer le graphique <strong>{{widgetTitle}}</strong> ?',
        delete_dashboard: 'Supprimer le tableau de bord courant',
        delete_dashboard_sure: `
          Êtes-vous sûr de vouloir supprimer le tableau de bord <strong>{{dashboardTitle}}</strong> ?
        `,
        widget_saved: 'Graphique enregistré',
        widget_saved_error: 'Erreur lors de l\'enregistrement du graphique',
        widget_deleted: 'Graphique supprimé',
        widget_deleted_error: 'Erreur lors de la suppression du graphique',
        layout_saved: 'Grille enregistrée',
        dashboard_saved: 'Tableau de bord enregistré',
        dashboard_saved_error: 'Erreur lors de l\'enregistrement du tableau de bord',
        dashboard_deleted: 'Tableau de bord supprimé',
        dashboard_deleted_error: 'Erreur lors de la suppression du tableau de bord',
        context_menu: {
          filter: 'Filtrer',
          quick_results: 'Résultats Rapides',
          exclude: 'Exclure',
          exploration: 'Exploration',
        },
      },
      ui: {
        back: 'Retour',
        back_to_results: 'Retour aux résultats',
        show_in_results: 'Voir dans les résultats',
        show_in_dashboard: 'Voir dans le dashboard',
        yes: 'Oui',
        no: 'Non',
        cancel: 'Annuler',
        close: 'Fermer',
        expand: 'Agrandir',
        collapse: 'Réduire',
        new: 'Nouveau',
        mine: 'Personnel',
        shared: 'Partagé',
        copy_to_clipboard: 'Copier dans le presse-papier',
        copied_to_clipboard: 'Copié !',
        cant_download_image: 'Impossible de télécharger l\'image',
      },
      results: {
        criteria: {
          edit: 'Editer le critère',
          noedit: 'Fermer l\'édition du critère',
          add: 'Avec ce critère',
          remove: 'Sauf ce critère',
          delete: 'Supprimer ce critère',
          search: 'Rechercher',
          reset: 'Réinitialiser',
          period: 'Chercher sur une période',
        },
        pagination: {
          on: 'sur',
          display_rows: 'Afficher :',
        },
        checkbox: {
          all: 'Tout sélectionner',
        },
        facets: {
          refine: 'Raffiner',
          show_facets: 'Afficher les facettes',
          show_more: 'voir plus',
          show_less: 'voir moins',
        },
        no_results: 'Aucun résultat',
        no_search_results: 'La recherche ne renvoie aucun résultat',
        no_search_results_create_alert: 'Aucun résultat trouvé. Créez une alerte pour recevoir la nouveauté',
        please_make_a_search: 'Veuillez renseigner au moins un critère de recherche',
        article_image: 'Illustration de l\'article',
        img_not_found: 'Image non trouvée',
        error_fetching_results: 'Erreur lors de la récupération des résultats',
        total: 'total :',
        sort: 'Tri :',
        switch_annotated_text: 'Afficher/Masquer la version annotée du texte',
        switch_translated_text: 'Afficher/Masquer la version traduite du texte',
        document_one: 'Document',
        document_other: 'Documents',
        relation: 'Relation',
        transcription: 'Transcription',
        pdf: 'PDF',
        comments: {
          comment: 'Commenter',
          hide_comments: 'Masquer les commentaires',
          show_comments_one: 'Voir le commentaire',
          show_comments_other: 'Voir les {{count}} commentaires',
          write_comment: 'Ecrire un commentaire',
          delete: 'Supprimer ce commentaire',
          deleted: 'Le commentaire a été supprimé',
          created: 'Le commentaire a été créé',
          be_alerted: 'Soyez alerté si un nouveau commentaire est ajouté',
          post: 'post',
          alert_updated: 'Alerte modifiée',
          be_alerted_disabled: `Les alertes sur les commentaires ne sont possibles
                               qu'après avoir commenté vous-même la ressource.`,
          error_deleting_comment: 'Erreur lors de la suppression du commentaire',
          error_fetching_comments: 'Erreur lors de la récupération des commentaires',
          error_submiting_comment: 'Erreur lors de la soumission du commentaire',
          error_updating_comment_alert: 'Erreur lors de la mise à jour de l\'alerte du commentaire',
        },
        tags: {
          more: 'Voir plus de tags',
          less: 'Voir moins de tags',
        },
        morelikethis: {
          see_also_label: 'Voir aussi :',
          show_see_also: 'Voir aussi',
          hide_see_also: 'Masquer le "voir aussi"',
          error_fetching_morelikethis: 'Erreur lors de la récupération d\'autres résultats',
        },
        duplicates: {
          show_duplicates: 'Voir les doublons',
          hide_duplicates: 'Masquer les doublons',
          error_fetching_duplicates: 'Erreur lors de la récupération des doublons',
        },
        close_window: 'Fermer la fenêtre',
      },
      document: {
        annotated_text: 'Texte annoté',
        clause: 'Clause',
        faces: 'Détection de visages :',
        frames: 'Détection de scènes :',
        open_document: 'Ouvrir le document',
        pdf: 'PDF',
        title_undefined: 'titre absent',
        translation: 'traduction',
        hidden_intervention_one: '{{count}} intervention masquée',
        hidden_intervention_other: '{{count}} interventions masquées',
        show_datetime_off: 'Afficher la date et l\'heure',
        show_datetime_on: 'Afficher la durée',
        show_all_speakers: 'Afficher tous les intervenants',
        hide_all_speakers: 'Cacher tous les intervenants',
        date_in_location: '{{when}} à {{where}}',
        check_all_sentences: 'Cocher tous les visibles',
      },
      selection: {
        your_selection: 'Votre sélection',
        show: 'Voir la sélection',
        remove: 'Retirer de la sélection',
        clear: 'Vider la sélection',
        no_selection: 'La sélection est vide',
      },
      carts: {
        choose: 'Sélectionnez un panier',
        list: 'Liste des paniers',
        back_to_list: 'Retour à la liste des paniers',
        no_carts: 'Aucun panier',
        error_fetching_carts: 'Erreur lors de la récupération des paniers',
        error_fetching_cart: 'Erreur lors de la récupération du panier',
        name: 'Nom',
        create: 'Créer',
        create_and_add: 'Créer et ajouter',
        create_new: 'Créer un nouveau panier',
        create_new_and_add: 'Créer un nouveau panier avec les documents',
        add: 'Ajouter',
        add_here: 'Ajouter les documents dans ce panier',
        name_of_new: 'Nom du nouveau panier',
        pick_from_list: 'Choisir un panier dans la liste',
        date_of_creation: 'Date de création',
        visibility: 'Visibilité',
        visibility_values: {
          1000: 'Privé',
          1: 'Collaboratif',
          2: 'Société',
          3: 'Public',
          4: 'Profile',
        },
        image: 'Image',
        owner: 'Propriétaire',
        contributors: 'Contributeurs',
        number_of_documents: 'Nombre de documents',
        delete: 'Suppression',
        delete_carts_one: 'Supprimer {{count}} panier',
        delete_carts_other: 'Supprimer {{count}} paniers',
        carts_deleted_one: 'Panier supprimé',
        carts_deleted_other: '{{count}} paniers supprimés',
        error_deleting_carts_one: 'Erreur lors de la suppression du panier',
        error_deleting_carts_other: 'Erreur lors de la suppression des {{count}} paniers',
        no_document: 'Aucun document',
        delete_cart_documents_one: 'Supprimer {{count}} document ?',
        delete_cart_documents_other: 'Supprimer {{count}} documents ?',
        cart_documents_deleted_one: 'Document supprimé',
        cart_documents_deleted_other: '{{count}} documents supprimés',
        error_deleting_cart_documents_one: 'Erreur lors de la suppression du document',
        error_deleting_cart_documents_other: 'Erreur lors de la suppression des {{count}} documents',
        cart_updated: 'Panier modifié',
        error_updating_cart: 'Erreur lors de la modification du panier',
        docs_added_to_cart_one: '{{count}} document ajouté au panier',
        docs_added_to_cart_other: '{{count}} documents ajoutés au panier',
        error_adding_to_cart: "Erreur à l'ajout dans le panier",
        send_cart_documents_one: 'Envoyer le panier avec le document coché',
        send_cart_documents_other: 'Envoyer le panier avec les documents cochés',
        summarize_cart_documents_one: 'Résumer le document coché',
        summarize_cart_documents_other: 'Résumer les documents cochés',
      },
      newsletters: {
        choose: 'Sélectionnez une newsletter',
        list: 'Liste des newsletters',
        back_to_list: 'Retour à la liste des newsletters',
        no_newsletters: 'Aucune newsletter',
        error_fetching_newsletters: 'Erreur lors de la récupération des newsletters',
        error_fetching_themes: 'Erreur lors de la récupération des thèmes',
        error_fetching_newsletter: 'Erreur lors de la récupération de la newsletter',
        name: 'Nom',
        create: 'Créer',
        create_and_add: 'Créer et ajouter',
        create_new: 'Créer une nouvelle newsletter',
        create_new_and_add: 'Créer un nouvelle newsletter avec les documents',
        add: 'Ajouter',
        add_here: 'Ajouter les documents dans cette newsletter',
        name_of_new: 'Nom de la nouvelle newsletter',
        pick_from_list: 'Choisir une newsletter dans la liste',
        date_of_creation: 'Date de création',
        visibility: 'Visibilité',
        visibility_values: {
          1000: 'Privé',
          1: 'Collaboratif',
          2: 'Société',
          3: 'Public',
          4: 'Profile',
        },
        image: 'Image',
        introduction: 'Introduction',
        owner: 'Propriétaire',
        contributors: 'Contributeurs',
        theme: 'Thème',
        themes: 'Thèmes',
        manual_mode: 'Mode manuel',
        topic_without_label: 'SANS INTITULÉ',
        topics: 'Rubriques: ',
        topic: 'Rubrique',
        order: 'Ordre',
        doc_edit: 'Éditer le document',
        sending: 'Envoi',
        manual_sending: 'Envoi manuel',
        at: 'à',
        recipients_one: 'Destinataire',
        recipients_other: 'Destinataires',
        number_of_documents: 'Nombre de documents',
        delete: 'Suppression',
        delete_newsletters_one: 'Supprimer {{count}} newsletter ?',
        delete_newsletters_other: 'Supprimer {{count}} newsletters ?',
        newsletters_deleted_one: 'Newsletter supprimée',
        newsletters_deleted_other: '{{count}} newsletters supprimées',
        error_deleting_newsletters_one: 'Erreur lors de la suppression de la newsletter',
        error_deleting_newsletters_other: 'Erreur lors de la suppression des {{count}} newsletters',
        no_document: 'Aucun document',
        delete_newsletter_documents_one: 'Supprimer {{count}} document ?',
        delete_newsletter_documents_other: 'Supprimer {{count}} documents ?',
        newsletter_documents_deleted_one: 'Document supprimé',
        newsletter_documents_deleted_other: '{{count}} documents supprimés',
        error_deleting_newsletter_documents_one: 'Erreur lors de la suppression du document',
        error_deleting_newsletter_documents_other: 'Erreur lors de la suppression des {{count}} documents',
        newsletter_updated: 'Newsletter modifiée',
        error_updating_newsletter: 'Erreur lors de la modification de la newsletter',
        docs_added_to_newsletter_one: '{{count}} document ajouté à la newsletter',
        docs_added_to_newsletter_other: '{{count}} documents ajoutés à la newsletter',
        error_adding_to_newsletter: "Erreur à l'ajout dans la newsletter",
        scheduled_sending_days: 'Envoi programmé - Jours',
        scheduled_sending_hours: 'Envoi programmé  - Heures',
        send_and_wipe_confirmation_title: 'Envoi de la newsletter',
        send_and_wipe_confirmation: `
          Vous vous apprêtez à envoyer cette newsletter, tous ses documents seront détachés après envoi.
          Êtes-vous sûr de vouloir faire l'envoi ?
        `,
        send_and_wipe_documents: 'Envoyer et vider',
        send_success: 'Envoi effectué',
        send_error: 'Erreur lors de l\'envoi',
        send_test: 'Envoi de test',
        send_newsletter_documents_one: 'Envoyer le panier avec le document coché',
        send_newsletter_documents_other: 'Envoyer le panier avec les documents cochés',
        sent: 'Envoyé',
        sending_date: 'Date d\'envoi',
        wiped: 'Vidée',
        historic_list: 'Historique des envois',
        actions: 'Actions',
        show_sent: 'Afficher le mail envoyé',
        resend: 'Ré-envoyer',
        resend_confirmation: `
          Vous vous apprêter à ré-envoyer cette newsletter.
          Êtes-vous sûr de vouloir refaire l'envoi ?
        `,
        edit: {
          details: 'Détail',
          documents: 'Documents',
          visualize: 'Visualiser',
          historic: 'Historique',
          topics: 'Rubriques',
          create_topic: 'Créer une rubrique',
          manage_topics: 'Gestion des rubriques',
          reorganizeNaturalOrder: 'Grouper par rubrique et trier les articles',
        },
      },
      ged: {
        add_transcription_annotation: 'Ajouter une annotation',
        delete_transcription_annotation: 'Supprimer l\'annotation',
        edit_transcription_annotation: 'Modifier l\'annotation',
        add_transcription_annotation_at:
          'Annoter à {{start}}',
        add_transcription_annotation_sure:
          'Ajout d\'une annotation à <strong>{{start}}</strong>.',
        edit_transcription_annotation_sure:
          'Modifier l\'annotation située à <strong>{{start}}</strong> ?',
        delete_transcription_annotation_sure:
          'Êtes-vous sûr de vouloir supprimer l\'annotation <i><strong>{{annotation}}</strong></i> ?',
        error_fetching_document: 'Erreur lors de la récupération du document',
        show_speaker: 'Afficher l\'intervenant',
        hide_speaker: 'Masquer l\'intervenant',
        edit_speaker_dialog_title: 'Renommer l\'intervenant',
        edit_speaker_here: 'Renommer l\'intervenant pour cette intervention',
        edit_speaker_full: 'Renommer l\'intervenant pour toutes les interventions',
        edit_speaker_sure: 'Renommer l\'intervenant <strong>{{speakerId}}</strong>{{forSentenceAt}} ?',
        edit_speaker_sure_for_sentence_at: 'pour la phrase commençant à',
        new_speaker_name: 'Nouveau nom',
        save_document_error: 'Erreur lors de la sauvegarde du document',
        save_document_success: 'Document enregistré',
        delete_documents_one: 'Supprimer le document ?',
        delete_documents_other: 'Supprimer les {{count}} documents ?',
        delete_documents_success_one: 'Le document a bien été supprimé',
        delete_documents_success_other: 'Les {{count}} documents ont bien été supprimés',
        delete_documents_error: 'Une erreur est survenue',
        update_status_to_one: 'Modifier l\'état du document à "{{status}}" ?',
        update_status_to_other: 'Modifier l\'état des {{count}} documents à "{{status}}" ?',
        update_status_documents_success_one: 'L\'état a bien été modifié pour le document',
        update_status_documents_success_other: 'L\'état a bien été modifié pour les {{count}} documents',
        update_status_documents_error: 'Impossible de modifier l\'état',
        update_all_status_to: 'Passer tout à "{{status}}" ?',
        prev_doc: 'Document précédent',
        next_doc: 'Document suivant',
        warning_concurrent_access: `Vous ne pouvez pas mettre à jour ce document car
          l'utilisateur "{{user}}" l'a déjà ouvert pour le modifier.`,
        document: {
          status: {
            1: 'Nouveau',
            2: 'Doublon',
            3: 'Rejeté',
            4: 'Collecté',
            5: 'Erreur',
            50: 'Validé',
            100: 'Publié',
            200: 'Super utilisateur',
            203: 'Supprimé',
            204: 'Lié',
            205: 'Fusionné',
            206: 'Commenté',
            207: 'Dupliqué',
            220: 'Classé',
            230: 'Dédoublonné',
            240: 'Favori',
            1000: 'Non précisé',
            1001: 'À compléter',
          },
        },
      },
      actions: {
        actions: 'Actions',
        add_to_cart: 'Ajouter au panier',
        add_to_newsletter: 'Ajouter à la newsletter',
        create_alert: 'Créer une alerte',
        copied: 'Copié !',
        download_transcription: 'Télécharger la transcription complète',
        download_partial_transcription: 'Télécharger les phrases sélectionnées de la transcription',
        error_copying_to_clipboard: 'Erreur lors de la copie dans le presse-papier.',
        manage_alert: 'Gérer les alertes',
        create: 'Créer',
        send_to_friend: 'Envoyer par mail',
        edit: 'Modifier',
        edition: 'Édition',
        comment: 'Commenter',
        summarize: 'Résumer',
        open_in_new_tab: 'Ouvrir dans un nouvel onglet',
        view_attachment: 'Voir la pièce jointe',
        delete: 'Supprimer',
        send: 'Envoyer',
        update_status: 'Modifier l\'état',
        export: 'Exporter',
        change_password: 'Changer le mot de passe',
        cancel_change_password: 'Annuler le changement de mot de passe',
      },
      alerts: {
        list: 'Liste des alertes',
        no_alerts: 'Aucune alerte',
        error_fetching_alerts: 'Erreur lors de la récupération des alertes',
        error_fetching_alert: 'Erreur lors de la récupération de l\'alerte',
        details: 'Détails',
        criteria: 'Critères',
        name: 'Nom',
        type: 'Type',
        date_of_creation: 'Date de création',
        type_values: {
          1: 'Alerte',
          6: 'Requête',
          240: 'Requête dashboard',
        },
        date_validity: 'Date de validité',
        periodicity: 'Périodicité',
        mode_values: {
          1: 'Quotidienne',
          2: 'Hebdomadaire',
          3: 'Mensuelle',
          15: 'planifiée',
          20: 'Toutes les 4 heures',
          30: 'Toutes les 2 heures',
          40: 'Toutes les heures',
          50: 'Toutes les 30 minutes',
          60: 'Toutes les 15 minutes',
          70: 'Toutes les 5 minutes',
          80: 'Toutes les minutes',
        },
        email: 'Email',
        format_values: {
          1: 'Sommaire, documents en ligne',
          2: 'Documents',
          1000: 'Notification par email, documents en ligne',
        },
        format_reception: 'Format de réception',
        frequence: 'Fréquence',
        date_of_execution: 'Exécution',
        number_of_documents: 'Nombre de documents',
        date_of_visualisation: 'Visualisation',
        date_of_next_execution: 'Prochaine exécution',
        delete: 'Suppression',
        delete_alerts_one: 'Supprimer {{count}} alerte',
        delete_alerts_other: 'Supprimer {{count}} alertes',
        alerts_deleted_one: 'Alerte supprimée',
        alerts_deleted_other: '{{count}} alertes supprimées',
        error_deleting_alerts_one: 'Erreur lors de la suppression de l\'alerte',
        error_deleting_alerts_other: 'Erreur lors de la suppression des {{count}} alertes',
        status: 'État',
        statuses: {
          1: 'Initial',
          2: 'Normal',
          3: 'Bloquée',
          4: 'Réinitialisée',
          5: 'Desactivée',
          6: 'Supprimée',
          7: 'Provisoire',
          1000: 'Non precisé',
        },
        active_one: 'active',
        active_other: 'actives',
        inactive_one: 'inactive',
        inactive_other: 'inactives',
        activate: 'Activer',
        deactivate: 'Désactiver',
        search: 'Rechercher avec les critères de l\'alerte',
        alerts_activate_one: 'L\'alerte a été activée',
        alerts_activate_other: 'Les {{count}} alertes ont été activées',
        alerts_deactivate_one: 'L\'alerte a été désactivée',
        alerts_deactivate_other: 'Les {{count}} alertes ont été désactivées',
        alert_updated: 'L\'alerte a été modifiée',
        error_updating_alert: 'Erreur lors de la modification de l\'alerte',
        alert_created: 'L\'alerte "{{alert}}" a été créée',
        error_creating_alert: 'Erreur lors de la création de l\'alerte',
        alert_type_alert: 'Alerte',
        alert_type_request: 'Requête sauvegardée',
        error_fetching_form: 'Erreur lors de la récupération des critères de recherche',
      },
      send_to_friend: {
        send_to: 'Envoyer à',
        recipients: 'Destinataires',
        to: 'À',
        cc: 'Cc',
        bcc: 'Cci',
        subject: 'Objet',
        error_building_dialog: 'Erreur lors de la préparation de l\'envoi',
        error_sending: 'Erreur à l\'envoi des documents',
        docs_sent_one: 'Document envoyé',
        docs_sent_other: '{{count}} documents envoyés',
        display_cc_field: 'Ajouter des destinataires en Cc',
        display_bcc_field: 'Ajouter des destinataires en Cci',
        comment: 'Message',
        attachment: 'Pièce jointe',
      },
      export: {
        select_format: 'Selectionner le format',
        selection: 'Sélection',
        massive: 'Massif',
        number_to_export_sel: 'Nombre de documents à exporter',
        number_to_export: 'Nombre de documents à exporter (max. {{max}})',
        number_exported_docs_one: 'Exporter un document',
        number_exported_docs_other: 'Exporter {{count}} documents',
        select_fields: 'Selectionner les champs à exporter',
        error_exporting: 'Erreur à l\'export des documents',
        docs_exported_one: 'Document exporté',
        docs_exported_other: '{{count}} documents exportés',
        massive_export_conf: 'L\'export que vous avez demandé va vous être envoyé par email dans quelques minutes',
      },
      summarize: {
        error_summarizing: 'Une erreur est survenue pendant le calcul du résumé',
        summary: 'Résumé',
        no_summary: 'Aucun résumé calculé.',
      },
      schedules: {
        header: {
          name: 'Nom',
          base: 'Base',
          creation_date: 'Date de création',
        },
        tabs: {
          details: {
            tab_name: 'détails',
            name: 'Nom',
            base: 'Base',
            creation_date: 'Date de création',
            modification_date: 'Date de modification',
            mode_rapport: 'Déclanchement du rapport',
            etat_schedule: 'État',
            observations: 'Observations',
            launch_now: 'lancer maintenant',
            launch_now_in_retry_mode: '... en mode reprise',
            last_occurence: 'dernier passage : ',
            next_occurence: 'prochain passage : ',
          },
          traitements: {
            tab_name: 'traitements',
          },
          programmation: {
            tab_name: 'planification',
          },
          reports: {
            columns: {
              reports: 'Rapports',
              tasks: 'Traitements',
              details: 'Détails',
            },
            tab_name: 'rapports',
            no_reports: 'Il n\'y a pas encore de rapport',
            select_report: 'Séléctionner un rapport dans le panneau de gauche',
            select_task: 'Séléctionner un traitement dans le panneau de gauche',
            task_start: 'début du traitement à : ',
            task_end: 'fin du traitement à : ',
            task_duration: 'durée du traitement : ',
            recovery: 'reprise',
            view_raw_log: 'View raw log',
            statuses: {
              1: 'Le traitement s\'est bien déroulé',
              2: 'Le traitement n\'a pas de fichier à traiter.',
              3: 'Le traitement est en erreur',
              4: 'Une erreur s\'est produite dans au moins une des tâches',
              1000: 'désactivé',
            },
          },
        },
        statuses: {
          1: 'Active',
          2: 'Inactive',
          1000: 'Non précisé (Inactive)',
        },
        report_modes: {
          1: 'Systématique',
          2: 'Si erreur',
          3: 'Si traitement',
          1000: 'Sans rapport',
        },
        toast: {
          schedules_activate_one: 'Tâche activée',
          schedules_activate_other: '{{count}} tâches activées',
          schedules_deactivate_one: 'Tâche désactivée',
          schedules_deactivate_other: '{{count}} tâches désactivées',
          schedule_updated: 'Tâche planifiée mise à jour',
          error_updating_schedule: 'Mise à jour de la tâche impossible',
        },
        list: 'Liste des tâches planifiées',
        active_one: 'active',
        active_other: 'actives',
        inactive_one: 'inactive',
        inactive_other: 'inactives',
        activate: 'Activer',
        deactivate: 'Désactiver',
      },
      users: {
        new: 'Nouveau',
        civilities: {
          mrs: 'Madame',
          miss: 'Mademoiselle',
          mr: 'Monsieur',
          unknown: 'Non précisé',
        },
        civility: 'Civilité',
        firstname: 'Prénom',
        lastname: 'Nom',
        languages: {
          fr: 'Français',
          en: 'Anglais',
        },
        language: 'Langue',
        list: 'Liste des utilisateurs',
        user: 'Utilisateur',
        logon: 'Identifiant',
        email: 'Mail',
        password: 'Mot de passe',
        password_confirm: 'Confirmer le mot de passe',
        password_not_matching: 'Les mots de passe doivent être identiques',
        actual_password: 'Mot de passe actuel',
        new_password: 'Nouveau mot de passe',
        profile_type: 'Type de profil',
        actives: 'Actifs',
        inactives: 'Inactifs',
        enable: 'Activer',
        disable: 'Désactiver',
        edit: 'Modifier',
        edit_title: 'Modification de l\'utilisateur :',
        create_title: 'Création d\'un nouvel utilisateur',
        users_enabled_one: 'L\'utilisateur a été activé',
        users_enabled_other: 'Les {{count}} utilsateurs ont été activés',
        users_disabled_one: 'L\'utilisateur a été désactivé',
        users_disabled_other: 'Les {{count}} utilisateurs ont été désactivées',
        no_users: 'Aucun utilisateur',
        error_fetching_users: 'Erreur lors de la récupération des utilisateurs',
        error_updating_user: 'Erreur lors de la modification de l\'utilisateur',
      },
      profile: {
        account: 'Mon profil',
        user_saved: 'Utilisateur enregistré',
        user_saved_error: 'Erreur lors de l\'enregistrement de l\'utilisateur',
        upload_avatar_error: 'Erreur lors de l\'envoi de l\'image',
        profile_saved: 'Profil enregistré',
        profile_saved_error: 'Erreur lors de l\'enregistrement du profil',
        profile_saved_warning_1: 'Ce mot de passe a déjà été utilisé et ne peut pas être réutilisé',
        profile_saved_warning_2: 'Les mots de passe doivent être identiques',
        profile_saved_warning_3: 'Le mot de passe saisi n\'est pas le bon',
        upload_img_user: 'Mettre une image de profil',
        zoom: 'Zoom',
        crop_image: 'Recadrez votre image',
        avatar_saved: 'Image de profil sauvegardée',
        avatar_saved_error: 'Erreur lors de l\'enregistrement de l\'image de profil',
        theme: {
          light: 'Clair',
          dark: 'Sombre',
          system: 'Système',
        },
      },
      anr: {
        opened_in_new_tab: 'Cet article a été ouvert dans un nouvel onglet',
        show_folders: 'Afficher les dossiers',
        news: 'actualités',
        news_one: 'actualité',
        news_other: 'actualités',
        quotes: 'citations',
        quotes_one: 'citation',
        quotes_other: 'citations',
        copy: 'Copie',
        new: 'Nouveau',
        new_folder: 'Nouveau dossier',
        delete_folder: 'Suppression de dossier',
        delete_folder_sure: 'Etes-vous sûr de vouloir supprimer le dossier',
        folder_deleted: 'Dossier supprimé',
        create_in_branch: 'Créér un dossier dans cette branche',
        duplicate: 'Dupliquer',
        too_much_folders: 'Vous ne pouvez pas créér de dossier supplémentaire',
        glimpse: 'Œil sur l\'actualité',
        favorite: 'Favori',
        favorites: 'Favoris',
        add_favorite: 'Ajouter aux favoris',
        remove_favorite: 'Retirer des favoris',
        no_query: 'Ce dossier n\'a pas de requête',
        go_to_last_validated: 'Aller au dernier article validé',
        expert_mode: 'Requête full-text en mode expert',
        query: 'Requête expert',
        text_query: 'Requête full-text',
        query_at_least: 'Les pages doivent contenir AU MOINS UN des mots suivants',
        query_every: 'Les pages doivent contenir TOUS les mots suivants',
        query_banned: 'Les pages ne doivent contenir AUCUN des mots suivants',
        quotes_query: 'Requête sur les réseaux sociaux',
        partner: 'Bouquet',
        partners: 'Bouquets',
        category: 'Rubrique thématique',
        categories: 'Rubriques thématiques',
        not_categories: 'Rubriques refusées',
        query_twitter: 'Requête Twitter',
        query_twitter_help: `Format des requêtes Twitter :<br/>
          ET : <i>mot1 mot2</i><br/>
          OU : <i>mot1 OR mot2</i><br/>
          Combinaison ET et OU : <i>mot1 mot2 OR mot3</i><br/>
          Expression exacte : <i>"mot1 mot2 mot3"</i><br/>
          Exclusion : <i>-mot</i><br/>
          Hashtag : <i>#mot</i><br/>
          Envoyé par : <i>from:pseudo</i><br/>
          Envoyé à : <i>to:pseudo</i><br/>
          Référence à : <i>@pseudo</i>`,
        query_linkedin: 'Requête LinkedIn',
        query_linkedin_help: `Format des requêtes LinkedIn :<br/>
          ET : <i>mot1 AND mot2</i><br/>
          OU : <i>mot1 OR mot2</i><br/>
          Expression exacte : <i>"mot1 mot2"</i><br/>
          Exclusion : <i>-mot</i>`,
        query_youtube: 'Requête YouTube',
        query_youtube_help: `Format des requêtes YouTube :<br/>
          ET : <i>mot1 mot2</i><br/>
          OU : <i>mot1 | mot2</i><br/>
          Expression exacte : <i>"mot1 mot2"</i><br/>
          Exclusion : <i>-mot</i>`,
        query_dailymotion: 'Requête Dailymotion',
        query_dailymotion_help: `Format des requêtes Dailymotion :<br/>
          ET : <i>"mot1 mot2"</i><br/>
          OU : <i>mot1 mot2</i>`,
        query_twitter_min_followers: 'Nombre de followers minimum',
        filter_links_only: 'Filtrer les citations avec lien uniquement',
        global_options: 'Options générales',
        show_in_glimpse: "Mettre ce dossier dans l'Œil sur l'actualité",
        focus_in_glimpse: "Mettre en avant les articles dans l'Œil sur l'actualité",
        recipients_daily_summary: 'Destinataires du récapitulatif quotidien',
        send_daily_summary: 'Envoyer par mail un récapitulafif quotidien',
        lang: 'langue',
        langs: 'langues',
        no_articles_from_test: 'Aucun article ne correspond aux paramètres',
        articles_from_test: 'articles correspondent aux paramètres',
        other_options: 'Autres options',
        error_fetching_folders: 'Erreur lors de la récupération des dossiers',
        error_fetching_folder: 'Erreur lors de la récupération du dossier',
        error_user_fetch: "Erreur lors de la récupération de l'utilisateur Ask'n'Read",
        error_fetching_news: 'Erreur lors de la récupération des actualités',
        error_fetching_quotes: 'Erreur lors de la récupération des citations',
        error_fetching_sources: 'Could not fetch sources',
        error_saving_folder: "Erreur lors de l'enregistrement du dossier",
        error_deleting_folder: 'Erreur lors de la suppression du dossier',
        error_bookmarking: "Erreur lors de l'enregistrement du favori",
        warning_unreachable_validated_article: "L'article validé a été supprimé ou n'est plus dans la liste",
        folder_created: 'Dossier créé',
        folder_updated: 'Dossier mis à jour',
        cant_open_article_message: `L'article ne peut pas être ouvert dans cette fenêtre,
          vous pouvez l'ouvrir dans un nouvel onglet en cliquant sur le bouton`,
        cant_open_dark_web_article_message: `Cet article provient du <strong>dark web</strong> et ne peut pas
          être ouvert dans cette fenêtre. Si vous êtes sur un navigateur <strong>Tor</strong> ou équivalent,
          vous pouvez l'ouvrir dans un nouvel onglet en cliquant sur le bouton`,
        or_copy_link_message: 'Ou alors, vous pouvez copier le lien en cliquant sur',
        cant_open_article_tip: `Astuce : vous pouvez ouvrir directement l'article dans
          un nouvel onglet depuis une liste d'articles en faisant un <strong>Ctrl + clic</strong> sur son titre.`,
        open_quote_link: 'Voir cette citation sur le réseau social',
        no_url_for_quote: "Aucune actualité n'a été partagée dans cette citation",
        retweeted_by: 'Retweeté par',
        sources: 'Sources',
        site: 'Site',
        sites: 'Sites',
        date_from: 'Du',
        date_to: 'Au',
        folders: 'Dossiers',
        filter_folders: {
          label: 'Filtrer dossiers',
          favorites: '[Favoris]',
          all: '[Tout]',
        },
        url: 'Adresse',
        type: 'Type',
        articles_count: 'Articles',
        address: 'Adresse du site ou de la page à surveiller',
        comment: 'Commentaire',
        folders_for_source: `Vous pouvez préciser le ou les dossier(s) dans le(s)quel(s)
          cette source doit remonter des articles`,
        partners_for_source: `Vous pouvez également préciser le ou les bouquet(s) dans
          le(s)quel(s) cette source doit être intégrée`,
        categories_for_source: `Vous pouvez également préciser la ou les rubrique(s)
          thématique(s) dans la(es)quelle(s) cette source doit être intégrée`,
        demand_source: "Demande d'ajout de source",
        demand_new_source_ok: "Demande d'ajout de source envoyée",
        demand_new_source_error: "Erreur lors de la demande d'ajout de source",
        unban: 'Débannir',
        unban_site: "Débannissement d'un site",
        unban_site_sure: 'Êtes-vous sûr de vouloir débannir',
        unbanned_site: 'Le site a été débanni',
        unbanning_site_error: 'Erreur lors de la demande de débannissement',
        name: 'Nom',
        action: 'Action',
        banned_sites: 'Sites bannis',
        ban_site: 'Bannissement de site',
        ban_site_sure: 'Êtes-vous sûr de vouloir bannir la source',
        ban_reason_label: 'Pour améliorer le service, vous pouvez indiquer la raison du bannissement',
        banned_site: 'Le site a été banni, le bannissement sera effectif dans quelques instants',
        banning_site_error: 'Erreur lors de la demande de bannissement',
        report_extraction_title: 'Signaler un découpage problématique des pages du site',
        report_extraction_text: `Voulez vous informer les administrateurs d'un découpage problématique
        des pages du site <strong>{{source}} </strong> ?
        Si cet article apparaît dans votre dossier à cause d'informations qui sont à l'extérieur du contenu
        de l'article (par exemple des informations dans un encadré "À lire aussi")
        , vous pouvez en informer les administrateurs qui prendront les mesures nécessaires.`,
        reported_extraction: 'La page du site a été signalé pour un mauvais découpage',
        reported_extraction_error: 'Erreur lors du signalement du découpage',
        item_one: 'article',
        item_other: 'articles',
        delete_articles_error: "Erreur lors de la suppression d'articles",
        delete_articles_ok_one: 'Article supprimé',
        delete_articles_ok_other: 'Articles supprimés',
        warning_submit_form_host: 'Appuyez sur la touche Entrée dans le champ texte pour valider votre site',
        warning_validate_host: 'Veuillez saisir un nom de domaine valide.',
        placeholder_host: 'Saisissez un site',
        helperText_host: 'Appuyez sur la touche Entrée pour valider chaque site',
        actions: {
          ban: 'Bannir',
          collect_done: 'Collecte réalisée avec succès',
          collect: 'Collecter',
          delete: 'Supprimer',
          delete_article: "Supprimer l'article",
          delete_quote: 'Supprimer la citation',
          delete_sure: 'Êtes-vous sûr de vouloir supprimer',
          error_collecting: 'Erreur lors de la collecte',
          link_copied: 'Lien copié',
          link_copy: 'Copier le lien',
          open_in_new_tab: 'Ouvrir dans une nouvelle fenêtre',
          share_facebook: 'Partager sur Facebook',
          share_linkedin: 'Partager sur LinkedIn',
          share_of: 'Partage de',
          share_twitter: 'Partager sur Twitter',
          share_whatsapp: 'Partager sur WhatsApp',
          share: 'Partager',
          translate: 'Ouvrir la version traduite',
          filter: 'Filtrer',
          filters: 'Filtres',
          filterOnSite: 'Filtrer sur le site',
          filterOnFolder: 'Filtrer sur le dossier',
          reset: 'Réinitialiser',
        },
      },
      account_menu: {
        signed_in_as: 'Connecté en tant que {{ logon }}',
        signed_in_as_female: 'Connectée en tant que {{ logon }}',
        logout: 'Se déconnecter',
        settings: 'Préférences',
        sources: 'Mes sources',
      },
      collections: {
        collection: {
          create: 'Créer une collection',
          create_dialog: 'Création d\'une collection',
          created: 'Collection créée',
          delete: 'Supprimer la collection',
          delete_dialog_one: 'Supprimer cette collection',
          delete_dialog_other: 'Supprimer ces {{count}} collections',
          delete_one: 'Êtes-vous sûr de vouloir supprimer la collection suivante ?',
          delete_other: 'Êtes-vous sûr de vouloir supprimer les collections suivantes ?',
          deleted_one: '{{count}} collection supprimée',
          deleted_other: '{{count}} collections supprimées',
          edit: 'Modifier la collection',
          edited: 'Collection modifiée',
          edit_dialog: 'Modification d\'une collection',
          error_creating: 'Erreur lors de la création d\'une collection',
          error_deleting_one: 'Erreur lors de la suppression d\'une collection',
          error_deleting_other: 'Erreur lors de la suppression des collections',
          error_editing: 'Erreur lors de la modification d\'une collection',
          params: 'Paramètre de la colletion',
          type: 'Collection',
          description_placeholder: 'Description de la collection...',
          libelle_placeholder: 'Le nom de la collection...',
        },
        corpus: {
          add: 'Ajouter un corpus',
          create: 'Créer un corpus',
          create_dialog: 'Création d\'un corpus',
          created: 'Corpus créé',
          delete: 'Supprimer le corpus',
          delete_dialog_one: 'Supprimer ce corpus',
          delete_dialog_other: 'Supprimer ces {{count}} corpus',
          delete_one: 'Êtes-vous sûr de vouloir supprimer le corpus suivant ?',
          delete_other: 'Êtes-vous sûr de vouloir supprimer les corpus suivants ?',
          deleted_one: '{{count}} corpus supprimé',
          deleted_other: '{{count}} corpus supprimés',
          description_placeholder: 'Ce corpus porte sur le sujet de...',
          edit: 'Modifier le corpus',
          edited: 'Corpus modifié',
          edit_dialog: 'Modification d\'un corpus',
          error_activated: 'Erreur lors de l\'activation d\'un corpus',
          error_creating: 'Erreur lors de la création d\'un corpus',
          error_deleting_one: 'Erreur lors de la suppression d\'un corpus',
          error_deleting_other: 'Erreur lors de la suppression des corpus',
          error_editing: 'Erreur lors de la modification d\'un corpus',
          libelle_placeholder: 'Le nom du corpus...',
          number_document_one: '{{count}} document',
          number_document_other: '{{count}} documents',
          params: 'Paramètres du corpus',
          type: 'Corpus',
          processing: 'Import en cours',
          monitoring: 'Suivre les imports',
          monitoring_qta: {
            columns: {
              progression_percentile: 'Progression',
              collection_id: 'ID de collection',
              start_date: 'Débuté le',
              end_date: 'Terminé le',
              duration: 'Durée',
              file: 'Fichier',
              status: 'État',
              type: 'Type',
            },
            filters: {
              running: 'En cours',
              finished: 'Terminé',
              interrupted: 'Suspendu',
              all: 'Tous',
            },
            force_refresh: 'Mettre à jour',
            refresh_success: 'Mise à jour réussi',
            refresh_error: 'Mise à jour impossible',
            title: 'Monitoring des imports',
          },
        },
        documents: {
          add: 'Ajouter des documents',
          add_dialog: 'Ajout de documents',
        },
        cannot_add_corpus: 'Vous ne pouvez pas créer de corpus, vous n\'avez pas de collections à vous',
        cannot_add_documents: 'Vous ne pouvez pas ajouter de documents, vous n\'avez pas de corpus',
        cannot_add_not_owner: 'Vous ne pouvez pas créer de corpus, vous n\'êtes pas le propriétaire',
        cannot_delete_not_owner: 'Vous ne pouvez pas supprimer, vous n\'êtes pas le propriétaire',
        cannot_delete_has_corpus: 'Veuillez supprimer les corpus présents afin de pouvour supprimer la collection',
        contributors: 'Contributeurs',
        contributors_placeholder: 'Sélectionnez des utilisateurs via l\'autocompletion...',
        description: 'Description',
        image: 'Image',
        error_fetching: 'Erreur lors de la récupération d\'une collection',
        lastest_activated: 'Corpus récemment ouverts',
        libelle: 'Nom',
        my_collections: 'Mes collections',
        next: 'Suivant :',
        no_document: 'Aucun document',
        no_results: 'Aucune collection',
        no_search_results: 'Aucune collection trouvée',
        search: 'Rechercher un corpus ou une collection...',
        visibility: 'Visibilité',
        visibility_values: {
          1000: 'Privé',
          2: 'Public',
          3: 'Collaboratif',
        },

      },
      mailing_lists: {
        list: 'Listes de diffusion',
        users: 'Utilisateurs',
        error_fetching: 'Erreur lors de la récupération de la liste de diffusion',
        name: 'Nom',
        create_dialog: 'Créer une liste de diffusion',
        created: 'Liste de diffusion créée',
        error_creating: 'Erreur lors de la création du groupe de diffusion',
        edit_dialog: 'Modifier la liste de diffusion',
        edited: 'Liste de diffusion modifié',
        error_editing: 'Erreur lors de la modification du groupe de diffusion',
        delete_dialog_one: 'Supprimer cette liste de diffussion',
        delete_dialog_other: 'Supprimer ces {{count}} listes de diffussion',
        delete_one: 'Voulez vous supprimer la liste de diffusion suivante ?',
        delete_other: 'Voulez vous supprimer les listes de diffusion suivantes ?',
        deleted_one: '{{count}} liste de diffusion supprimée',
        deleted_other: '{{count}} listes de diffusion supprimées',
        error_deleting_one: 'Erreur lors de la suppression de la liste de diffusion',
        error_deleting_other: 'Erreur lors de la suppression des listes de diffusion',
      },
      users_mailing_list: {
        all: 'Tout',
        create: 'Ajouter un email',
        create_dialog: 'Créer un email dans une ou plusieurs liste(s) de diffusion',
        created: 'L\'email a été créé',
        updated: 'L\'email a bien été ajouté à la liste de diffusion',
        deleted: 'L\'email a bien été supprimé de la liste de diffusion',
        delete_dialog: 'Supprimer un email',
        email: 'Email :',
        edited: 'L\'email a été modifié',
        error_form_mailing_list: 'Doit contenir au moins une liste de diffusion',
        error_creating: 'Erreur lors de la création de l\'email',
        error_deleting: 'Erreur lors du retrait de la liste de diffusion',
        error_editing: 'Erreur lors de la modification de l\'email',
        error_fetching: 'Erreur lors de la récupération des emails',
        filter_emails: 'Filtrer les emails',
        filter_mailing_list: 'Filtrer les listes de diffusions',
        mailing_list: 'Liste de diffusion :',
        message_delete_user_dialog: 'Voulez vous supprimer cet email ?',
        message_delete_dialog: `Il s'agit de la dernière liste de diffusion associée à cet email,
        si vous le retirez de cette liste de diffusion, vous supprimerez l'email de la liste des emails.<br>
        Souhaitez-vous continuer ?`,
        in_nogroup: 'Dans aucune liste de diffusion',
        in_onegroup: 'Dans au moins une liste de diffusion',
        in_allgroup: 'Dans toutes les listes de diffusion',
      },
      datatable: {
        loading: 'Chargement de la liste',
        textLabels: {
          body: {
            noMatch: 'Aucune entrée ne correspond',
            toolTip: 'Trier',
          },
          pagination: {
            next: 'Page suivante',
            previous: 'Page précédente',
            rowsPerPage: 'Lignes par page :',
            displayRows: 'de',
            jumpToPage: 'Aller à la page',
          },
          toolbar: {
            search: 'Rechercher',
            downloadCsv: 'Télécharger le CSV',
            print: 'Imprimer',
            viewColumns: 'Voir les colonnes',
            filterTable: 'Filtrer la table',
          },
          filter: {
            all: 'Tous',
            title: 'FILTRES',
            reset: 'RESET',
          },
          viewColumns: {
            title: 'Voir les colonnes',
            titleAria: 'Montrer/Cacher les colonnes',
          },
          selectedRows: {
            text: 'ligne(s) sélectionnée(s)',
            delete: 'Supprimer',
            deleteAria: 'Supprimer les lignes sélectionnées',
          },
        },
      },
    },
  },
};

if (CUSTOM_PATH) {
  const isDev = (process.env.NODE_ENV === 'development');
  try {
    // eslint-disable-next-line import/no-dynamic-require, global-require
    const { default: frCustom } = require(`custom/${CUSTOM_PATH}/locales/fr`);
    if (isDev) {
      console.info(`CUSTOM PATH LOADED : custom/${CUSTOM_PATH}/locales/fr`);
    }
    fr = _.merge(fr, frCustom);
  } catch (err) {
    _.noop();
  }
}

const finalFr = fr;
export default finalFr;
