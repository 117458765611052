import { useMemo } from 'react';
import { useSelector } from 'react-redux';
import _ from 'lodash';
import { t } from 'i18next';
import { toZonedTime } from 'date-fns-tz';

import {
  computeHtmlWithAnnotations,
  generateLinkToResource,
  getDocumentBaseId,
  getValueByBaseFieldsConfig,
  getValueOrFirstValueFromArray,
} from 'generic/utils/qesUtils';
import { format } from 'generic/utils/dateUtils';
import { useComputedTranscription } from 'generic/core/hooks/useComputedTranscription';
import { useColorSchemeDetector } from 'generic/core/hooks/useColorSchemeDetector';
import { cleanMultipleLines } from 'generic/utils/utils';

export default (document, shouldComputeCompleteFields) => {
  const { qesdocument } = document;

  const docBaseId = getDocumentBaseId(document);
  const documentBase = useSelector((state) => _.find(state.config.bases, { base: docBaseId }));
  const resultsCompleteVisibleInResults = useSelector((state) => state.ux.resultsCompleteVisibleInResults);
  const currentTheme = useColorSchemeDetector();
  const { speakersColors } = currentTheme.HIGHCHARTS;

  // noms des champs issues de la configuration de la base, permet d'être un peu dynamique
  // et avec de la chance, ne rien avoir à surcharger
  const id = getValueByBaseFieldsConfig(documentBase, qesdocument, 'champId');
  const statusXml = getValueByBaseFieldsConfig(documentBase, qesdocument, 'champEtat');
  const pdf = getValueByBaseFieldsConfig(documentBase, qesdocument, 'champPdf');
  const titleXml = getValueByBaseFieldsConfig(documentBase, qesdocument, 'champTitre');

  // noms des champs en durs, difficile de les rendre dynamique en l'état de l'API
  // on prend le premier élément du tableau de valeur s'il y en a plusieurs.
  let textOriginal = cleanMultipleLines(getValueOrFirstValueFromArray(qesdocument?.Text_original));
  const text = cleanMultipleLines(getValueOrFirstValueFromArray(qesdocument?.Text));
  let textTranslated = '';
  if (textOriginal) {
    textTranslated = text;
  } else {
    textOriginal = text;
  }
  let lang = getValueOrFirstValueFromArray(qesdocument?.Lang);
  if (lang === 'None') {
    lang = '';
  }

  const recordingDateZoned = useMemo(
    () => {
      const d = new Date(getValueOrFirstValueFromArray(qesdocument?.Date_publication));
      return toZonedTime(d, 'UTC');
    },
    [qesdocument?.Date_publication],
  );

  const fileSize = getValueOrFirstValueFromArray(qesdocument?.File_size);

  const attachedFile = getValueOrFirstValueFromArray(qesdocument?.File)
    || getValueOrFirstValueFromArray(qesdocument['File.verbatim']);

  //  dernières retouches avant le retour du résulat
  let attachedFileURL;
  if (attachedFile) {
    attachedFileURL = generateLinkToResource(docBaseId, attachedFile);
  }

  const videoExtensions = ['mp4', 'mov', 'avi', 'mkv', 'flv', 'wmv', 'webm', 'mpeg'];
  const audioExtensions = ['m4a', 'wav', 'mp3'];
  const imgExtensions = ['png', 'jpg', 'jpeg'];
  const isVideo = !_.isEmpty(attachedFile) && videoExtensions.includes(attachedFile.split('.').pop().toLowerCase());
  const isAudio = !_.isEmpty(attachedFile) && audioExtensions.includes(attachedFile.split('.').pop().toLowerCase());
  const isImage = !_.isEmpty(attachedFile) && imgExtensions.includes(attachedFile.split('.').pop().toLowerCase());
  const isPdf = !_.isEmpty(attachedFile) && attachedFile.toLowerCase().endsWith('.pdf');

  const title = titleXml || attachedFile || `[${t('document.title_undefined')}]`;
  const date = format(recordingDateZoned, 'PPPPp');
  const status = +statusXml;
  const snippet = _.truncate(textOriginal, {
    length: resultsCompleteVisibleInResults ? 150 : 500,
    separator: /,? +/,
  });

  const sentences = qesdocument?.Phrase;

  // Process "heavy" fields only needed in the "complete" panel
  let transcription = null;
  let htmlWithAnnotations = null;
  let frames = [];
  if (shouldComputeCompleteFields) {
    // Transcription with sentences, speakers, translations
    const textJson = getValueOrFirstValueFromArray(qesdocument?.Text_json);
    const transcriptionAnnotations = getValueOrFirstValueFromArray(qesdocument?.Text_comments_json);
    transcription = useMemo(() => useComputedTranscription(
      textJson,
      transcriptionAnnotations,
      textTranslated,
      recordingDateZoned,
      speakersColors,
    ), [textJson, transcriptionAnnotations, textTranslated, recordingDateZoned, speakersColors]);
    // Annotated html
    htmlWithAnnotations = computeHtmlWithAnnotations(getValueOrFirstValueFromArray(qesdocument?.QES_Html));
    // Scenes detections
    const frameUrls = qesdocument.Frame;
    const frameTimes = qesdocument.Frame_time;
    if (_.isArray(frameUrls)) {
      frames = frameUrls.map((frameUrl, index) => (
        {
          image: generateLinkToResource(docBaseId, frameUrl),
          time: frameTimes[index],
        }
      ));
    }
  }

  return {
    attachedFile,
    attachedFileURL,
    date,
    frames,
    htmlWithAnnotations,
    id,
    lang,
    fileSize,
    pdf,
    snippet,
    status,
    textOriginal,
    textTranslated,
    title,
    transcription,
    isAudio,
    isImage,
    isVideo,
    isPdf,
    sentences,
  };
};
