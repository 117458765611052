import React, { Fragment } from 'react';
import PropTypes from 'prop-types';
import { useTranslation } from 'react-i18next';
import _ from 'lodash';

import {
  Box,
  Card,
  CardMedia,
  FormControlLabel,
  FormGroup,
  Link,
  Switch,
  Typography,
} from '@mui/material';
import {
  AccountBalance,
  AccountCircle,
  Audiotrack,
  Label,
  Place,
  Videocam,
} from '@mui/icons-material';

import { overrideRessource } from 'generic/utils/utils';
import { documentPropType } from 'generic/core/qes/proptypes';
import ExpandableTagList from 'generic/components/ui/ExpandableTagList';
import MediaWithTranscription from 'generic/components/ui/MediaWithTranscription';
import ImgLoader from 'generic/components/ui/ImgLoader';
import ImageNotFound from 'generic/components/ui/ImageNotFound';

import QES_CONSTANTS from 'generic/core/qes/constants';
import { prettyPrintSize } from 'generic/utils/mathUtils';
import JsxJoinerWithSeparator from 'generic/components/ui/JsxJoinerWithSeparator';

const { default: useDocumentFields } = overrideRessource('core/hooks/useDocumentFields', true);
const { CLICK_ON_DOCS_TITLE_BEHAVIOR } = QES_CONSTANTS;

const Document = ({
  activeBaseId = null,
  activeUserId = null,
  document,
  disableTagsRefine = false,
  displayTitle = true,
  handleGetDocumentComplete = null,
  handleToggleTranslatedTextDisplay = null,
  isAnnotatedTextDisplayed = false,
  handleToggleAnnotatedTextDisplay = null,
  isCompleteDisplay = false,
  isTranslatedTextDisplayed = false,
  patchDocumentInComplete = null,
}) => {
  const { t } = useTranslation();

  const {
    attachedFileURL,
    date,
    fileSize,
    frames,
    htmlWithAnnotations,
    lang,
    linkout,
    textOriginal,
    textTranslated,
    title,
    transcription,
    isAudio,
    isImage,
    isVideo,
    isPdf,
    sentences,
  } = useDocumentFields(document, isCompleteDisplay);

  const { qesdocument } = document;

  let titleLinkProps;
  if (CLICK_ON_DOCS_TITLE_BEHAVIOR === 'newTab') {
    titleLinkProps = {
      component: Link,
      href: linkout,
      rel: 'noopener',
      target: '_blank',
      underline: 'none',
    };
  } else if (CLICK_ON_DOCS_TITLE_BEHAVIOR === 'complete') {
    titleLinkProps = {
      component: 'span',
      onClick: handleGetDocumentComplete,
    };
  }

  let tags;
  if (isCompleteDisplay) {
    tags = [
      { fieldName: 'QES_Company', icon: AccountBalance },
      { fieldName: 'QES_Person', icon: AccountCircle },
      { fieldName: 'QES_Location', icon: Place },
      { fieldName: 'QES_ConceptCategorized', icon: Label },
    ];
  } else {
    tags = [
      { fieldName: 'QES_Company_display', icon: AccountBalance },
      { fieldName: 'QES_Person_display', icon: AccountCircle },
      { fieldName: 'QES_Location_display', icon: Place },
      { fieldName: 'QES_ConceptCategorized_display', icon: Label },
    ];
  }

  const shouldDisplayHtmlWithAnnotations = htmlWithAnnotations
    && isAnnotatedTextDisplayed
    && (!textTranslated || !isTranslatedTextDisplayed);
  const shouldDisplayTextTranslated = !_.isEmpty(textTranslated) && isTranslatedTextDisplayed;
  const shouldDisplayTextOriginal = !shouldDisplayHtmlWithAnnotations && !shouldDisplayTextTranslated;
  const textSx = {
    mt: 2,
    whiteSpace: 'pre-wrap',
    lineHeight: '30px',
    clear: 'both',
  };
  return (
    <Box
      sx={{
        display: 'flex',
        flexDirection: 'column',
        flexGrow: '1',
        width: '100%',
        overflow: 'auto',
        wordBreak: 'break-word',
      }}
    >
      {displayTitle && (
        <Typography
          variant="h6"
          component="span"
          sx={{
            fontSize: '1.2rem',
            margin: '6px 0',
            display: 'block',
          }}
        >
          <Box
            {...titleLinkProps}
            color="text.primary"
            sx={{ cursor: 'pointer' }}
          >
            {title}
          </Box>
        </Typography>
      )}

      <Box sx={{ display: 'flex', alignItems: 'flex-start' }}>
        <Box sx={{ flexGrow: '1' }}>
          {isImage && !isCompleteDisplay && (
            <Box sx={{ mr: 1, mb: '2px', float: 'left' }}>
              <ImgLoader
                src={attachedFileURL}
                fallbackComponent={(
                  <ImageNotFound
                    height={60}
                    width={100}
                  />
                )}
                height={60}
                width={100}
                loading="lazy"
              />
            </Box>
          )}
          <Box sx={{ lineHeight: '1.3rem' }}>
            <Box sx={{ display: 'flex', flexWrap: 'wrap', mb: 0.5 }}>
              <JsxJoinerWithSeparator items={[
                date && (
                  <Typography
                    component="span"
                    variant="body2"
                    color="text.neutral"
                  >
                    {date}
                  </Typography>
                ),
                lang && (
                  <Typography
                    component="span"
                    variant="body2"
                    color="text.neutral"
                    className="item"
                  >
                    {lang}
                  </Typography>
                ),
                fileSize && (
                  <Typography
                    component="span"
                    variant="body2"
                    color="text.neutral"
                    className="item"
                  >
                    {prettyPrintSize(+fileSize)}
                  </Typography>
                ),
                attachedFileURL && (
                  <Link
                    href={attachedFileURL}
                    rel="noopener"
                    target="_blank"
                    underline="none"
                    sx={{ display: 'flex' }}
                  >
                    <Typography component="span" variant="body2">{t('document.open_document')}</Typography>
                  </Link>
                ),
                !isCompleteDisplay && (isAudio || isVideo) && (
                  <Fragment>
                    {isVideo && <Videocam fontSize="small" />}
                    {isAudio && <Audiotrack fontSize="small" />}
                  </Fragment>
                ),
              ]}
              />
            </Box>
            <Box sx={{ mt: 1 }}>
              <ExpandableTagList
                disableRefine={disableTagsRefine}
                qesdocument={qesdocument}
                tags={tags}
              />
            </Box>
          </Box>
        </Box>
      </Box>

      {sentences && (
        sentences.map((sentence) => (
          <Box
            sx={{
              p: 1,
              mt: 0.5,
              mb: 1,
              borderLeft: '5px solid #d3d3d3',
              whiteSpace: 'pre-wrap',
              lineHeight: '22px',
            }}
          >
            {sentence}
          </Box>
        ))
      )}

      {isCompleteDisplay && (
        <Fragment>
          {!isPdf && textTranslated && (
            <FormGroup>
              <FormControlLabel
                checked={isTranslatedTextDisplayed}
                control={<Switch onChange={handleToggleTranslatedTextDisplay} />}
                label={t('results.switch_translated_text')}
                sx={{ pl: '11px' }}
              />
            </FormGroup>
          )}
          {_.isEmpty(transcription) && (
            <Fragment>
              {isImage && (
                <Box sx={{ my: 3 }}>
                  <ImgLoader
                    src={attachedFileURL}
                    fallbackComponent={(
                      <ImageNotFound
                        height={350}
                        width="90%"
                      />
                    )}
                    width="90%"
                    minHeight={350}
                    loading="lazy"
                  />
                </Box>
              )}
              {isPdf && (
                <Box sx={{ display: 'flex', justifyContent: 'center', flexGrow: 1 }}>
                  <Card
                    sx={{
                      width: '90%',
                      mt: 2,
                      mb: 0,
                      flexShrink: 0,
                    }}
                  >
                    <CardMedia
                      sx={{
                        '&.MuiCardMedia-root': {
                          minHeight: '500px',
                          height: '100%',
                        },
                      }}
                      component="iframe"
                      controls
                      src={attachedFileURL}
                    />
                  </Card>
                </Box>
              )}
              {!isPdf && (
                <Fragment>
                  {(!textTranslated || !isTranslatedTextDisplayed) && htmlWithAnnotations && (
                    <FormGroup>
                      <FormControlLabel
                        checked={isAnnotatedTextDisplayed}
                        control={<Switch onChange={handleToggleAnnotatedTextDisplay} />}
                        label={t('results.switch_annotated_text')}
                        sx={{ pl: '11px' }}
                      />
                    </FormGroup>
                  )}
                  {shouldDisplayHtmlWithAnnotations && (
                    <Box
                      sx={{ ...textSx }}
                    >
                      <div
                        className="html-with-tags"
                        // eslint-disable-next-line react/no-danger
                        dangerouslySetInnerHTML={{ __html: htmlWithAnnotations }}
                      />
                    </Box>
                  )}
                  {shouldDisplayTextTranslated && (
                    <Box sx={{ ...textSx, mb: 2, display: 'flex' }}>
                      <Box>
                        {textOriginal}
                      </Box>
                      <Box sx={{ ml: 5 }}>
                        {textTranslated}
                      </Box>
                    </Box>
                  )}
                  {shouldDisplayTextOriginal && (
                    <Box sx={{ ...textSx, mb: 2 }}>
                      {textOriginal}
                    </Box>
                  )}
                </Fragment>
              )}
            </Fragment>
          )}
          {!_.isEmpty(transcription) && (
            <MediaWithTranscription
              activeBaseId={activeBaseId}
              activeUserId={activeUserId}
              displayTranslatedSentences={shouldDisplayTextTranslated}
              documentId={document.idext}
              frames={frames}
              mediaType={isVideo ? 'video' : 'audio'}
              mediaUrl={attachedFileURL}
              patchDocumentInComplete={patchDocumentInComplete}
              transcription={transcription}
            />
          )}
        </Fragment>
      )}
    </Box>
  );
};

Document.propTypes = {
  activeBaseId: PropTypes.number,
  activeUserId: PropTypes.number,
  disableTagsRefine: PropTypes.bool,
  displayTitle: PropTypes.bool,
  document: documentPropType.isRequired,
  handleGetDocumentComplete: PropTypes.func,
  handleToggleAnnotatedTextDisplay: PropTypes.func,
  handleToggleTranslatedTextDisplay: PropTypes.func,
  isAnnotatedTextDisplayed: PropTypes.bool,
  isCompleteDisplay: PropTypes.bool,
  isTranslatedTextDisplayed: PropTypes.bool,
  patchDocumentInComplete: PropTypes.func,
};

export default Document;
